<template>
  <div class="tw-flex tw-flex-col">
    <div
      v-if="aggregationInfo"
      class="tw-font-bold tw-text-base"
    >
      {{ showDates(aggregationInfo) }}
    </div>
    <div
      v-else
      class="tw-text-lg tw-font-bold"
    >
      {{ dateFormat(info.point.argument) }}
    </div>
    <div
      v-for="data of pointData"
      :key="data.label"
      class="tw-flex tw-gap-2 tw-text-base"
    >
      <div>
        {{ data.label }}:
      </div>
      <div class="tw-flex tw-gap-2 tw-font-bold">
        {{ isCurrencyFormat(data.label) ? currencyFormatter(data.value) : numberFormat(data.value) }}
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  name: 'ChartTooltip',
  props: {
    info: {
      type: Object,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
  },
  computed: {
    aggregationInfo() {
      return this.info.point.aggregationInfo
    },
    pointData() {
      return this.info.points.map(point => ({
        label: point.seriesName,
        value: point.value,
      }))
    },
  },
  methods: {
    showDates(aggregationInfo) {
      if (aggregationInfo.aggregationInterval === 'day') {
        return this.dateFormat(aggregationInfo.intervalStart)
      }
      const firstElement = aggregationInfo.data[0]
      const lastElement = aggregationInfo.data[aggregationInfo.data.length - 1]
      const startDate = this.dateFormat(firstElement.date)
      const endDate = this.dateFormat(moment(lastElement.date))
      return `${startDate} - ${endDate}`
    },
    dateFormat(date) {
      return moment(date).format('MMMM DD, YYYY')
    },
    currencyFormatter(value) {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: this.currency }).format(parseFloat(value.toFixed(2)))
    },
    numberFormat(value) {
      return new Intl.NumberFormat('en-US').format(value)
    },
    isCurrencyFormat(label) {
      return ['Revenue', 'Profit'].includes(label)
    },
  },
}
</script>

<style scoped lang="scss">

</style>
