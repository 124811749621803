<template>
  <div class="d-flex flex-column p-1">
    <div class="tw-flex tw-flex-row tw-flex-wrap tw-justify-end tw-gap-2 tw-items-baseline tw-p-1">
      <DxSelectBox
        v-model="series_1"
        class="flex-fill flex-sm-grow-0"
        :items="series1Options"
        display-expr="label"
        value-expr="value"
        @value-changed="onSeriesValueChange($event, 'leftDataAxis')"
      />
      <DxSelectBox
        v-model="series_2"
        class="flex-fill flex-sm-grow-0"
        :items="series2Options"
        display-expr="label"
        value-expr="value"
        @value-changed="onSeriesValueChange($event, 'rightDataAxis')"
      />
      <DxCheckBox
        v-model="isLabelShow"
        :value="isLabelShow"
        text="Show Labels"
        @value-changed="onShowLabelsChange"
      />
      <div class="d-flex align-items-center">
        <b-form-radio-group
          id="btn-radios-1"
          v-model="aggregationInterval"
          size="sm"
          button-variant="flat-primary"
          :options="aggregationIntervalOptions"
          buttons
          name="radios-btn-default"
          @change="onAggregationIntervalChange"
        />
      </div>
    </div>
    <DxChart
      id="sales_chart"
      style="max-height: 32vh; min-height: 32vh; height: 32vh;"
      :data-source="dataSource"
      :on-legend-click="onLegendClick"
    >
      <DxCommonSeriesSettings
        argument-field="date"
        type="line"
      />
      <DxValueAxis
        name="left"
        position="left"
      >
        <DxLabel
          :format="leftDataAxisFormat"
        />
      </DxValueAxis>
      <DxValueAxis
        v-if="isRightDataAxisVisible"
        name="right"
        position="right"
      >
        <DxLabel
          :format="rightDataAxisFormat"
        />
      </DxValueAxis>
      <DxArgumentAxis
        :aggregation-interval="aggregationInterval"
        :value-margins-enabled="false"
        argument-type="datetime"
      />
      <DxCrosshair
        :enabled="true"
        dash-style="dash"
      >
        <DxHorizontalLine :visible="false" />
      </DxCrosshair>
      <DxSeries
        axis="left"
        :value-field="series_1"
        :name="leftDataAxis.label"
      >
        <DxLabel
          :visible="isLabelShow"
          :customize-text="customizeTextSeries1"
        />
        <DxAggregation
          :enabled="true"
          method="sum"
        />
      </DxSeries>
      <DxSeries
        :value-field="series_2"
        :axis="isRightDataAxisVisible ? 'right' : 'left'"
        :name="rightDataAxis.label"
      >
        <DxLabel
          :visible="isLabelShow"
          :customize-text="customizeTextSeries2"
        />
        <DxAggregation
          :enabled="true"
          method="sum"
        />
      </DxSeries>
      <DxLegend
        :visible="true"
        vertical-alignment="bottom"
        horizontal-alignment="center"
      />
      <DxTooltip
        :enabled="true"
        :shared="true"
        content-template="tooltipTemplate"
      >
        <DxFormat
          :precision="1"
          type="largeNumber"
        />
      </DxTooltip>
      <template #tooltipTemplate="{ data }">
        <ChartTooltip
          :info="data"
          :currency="currency"
        />
      </template>
    </DxChart>
  </div>
</template>

<script>
import {
  DxAggregation,
  DxArgumentAxis,
  DxCommonSeriesSettings, DxCrosshair,
  DxFormat, DxHorizontalLine,
  DxLabel,
  DxLegend,
  DxSeries,
  DxTooltip,
  DxValueAxis,
} from 'devextreme-vue/chart'
import { DxSelectBox } from 'devextreme-vue'
import { BFormRadioGroup } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { DxCheckBox } from 'devextreme-vue/check-box'
import ChartTooltip from '@/views/sales_v2/components/ChartTooltip.vue'

const DxChart = () => import(/* webpackChunkName: "dxChart" */ 'devextreme-vue/chart').then(module => module.DxChart)

export default {
  name: 'Chart',
  components: {
    ChartTooltip,
    DxCrosshair,
    DxHorizontalLine,
    DxCheckBox,
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxValueAxis,
    DxTooltip,
    DxArgumentAxis,
    DxLabel,
    DxFormat,
    DxLegend,
    DxSelectBox,
    DxAggregation,
    BFormRadioGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    dataSource: {
      type: Array,
      required: true,
    },
    settings: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isLabelShow: false,
      series_1: 'profit',
      series_2: 'ordered_count',
      leftDataAxis: {
        label: 'Profit',
        format: 'currency',
      },
      rightDataAxis: {
        label: 'Unit Sold',
        format: 'common',
      },
      options: [
        {
          label: 'Profit',
          value: 'profit',
        },
        {
          label: 'Revenue',
          value: 'revenue',
        },
        {
          label: 'Refund Count',
          value: 'refund_count',
        },
        {
          label: 'Unit Sold',
          value: 'ordered_count',
        },
      ],
      axisConfigs: {
        revenue: {
          format: 'currency',
          label: 'Revenue',
        },
        profit: {
          format: 'currency',
          label: 'Profit',
        },
        ordered_count: {
          format: 'common',
          label: 'Unit Sold',
        },
        refund_count: {
          format: 'common',
          label: 'Refund Count',
        },
      },
      aggregationInterval: 'day',
      aggregationIntervalOptions: [
        { text: 'Day', value: 'day' },
        { text: 'Week', value: 'week' },
        { text: 'Month', value: 'month' },
      ],
    }
  },
  computed: {
    currency() {
      return this.dataSource[0].currency ?? 'USD'
    },
    currencySign() {
      return this.dataSource[0].currency_sign ?? this.dataSource[0].currency
    },
    isRightDataAxisVisible() {
      return this.leftDataAxis.format !== this.rightDataAxis.format
    },
    leftDataAxisFormat() {
      if (this.leftDataAxis.format === 'currency') {
        return { style: 'currency', currency: this.currency, useGrouping: true }
      }
      return {
        format: {
          type: 'fixedPoint',
          precision: 0,
        },
        useGrouping: false,
      }
    },
    rightDataAxisFormat() {
      if (this.rightDataAxis.format === 'currency') {
        return { style: 'currency', currency: this.currency, useGrouping: true }
      }
      return {
        format: {
          type: 'fixedPoint',
          precision: 0,
        },
        useGrouping: false,
      }
    },
    series1Options() {
      return this.options.filter(item => item.value !== this.series_2)
    },
    series2Options() {
      return this.options.filter(item => item.value !== this.series_1)
    },
  },
  created() {
    if (this.settings) {
      this.isLabelShow = this.settings.is_label_show
      this.aggregationInterval = this.settings.aggregation_interval
      this.series_1 = this.settings.series_1
      this.series_2 = this.settings.series_2
      this.leftDataAxis = this.axisConfigs[this.series_1]
      this.rightDataAxis = this.axisConfigs[this.series_2]
    }
  },
  methods: {
    onSeriesValueChange(data, index) {
      this[index] = this.axisConfigs[data.value]
      this.saveConfig()
    },
    onShowLabelsChange() {
      this.saveConfig()
    },
    onAggregationIntervalChange() {
      this.saveConfig()
    },
    saveConfig() {
      this.$store.dispatch('user/updateSettings', {
        index: 'sales_chart',
        payload: {
          is_label_show: this.isLabelShow,
          aggregation_interval: this.aggregationInterval,
          series_1: this.series_1,
          series_2: this.series_2,
        },
      }).then(response => {
        this.$bvToast.toast(response.message, {
          title: 'User Settings',
          variant: 'success',
          solid: true,
        })
      }).catch(() => {
        this.$bvToast.toast('Server error, please try again later.', {
          title: 'Oops!',
          variant: 'danger',
          solid: true,
        })
      })
    },
    numberFormat(value) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: this.currency,
        minimumFractionDigits: 0,
      }).format(value)
    },
    customizeTextSeries1({ valueText }) {
      if (['profit', 'revenue'].includes(this.series_1)) {
        return `${this.numberFormat(valueText)}`
      }

      return parseFloat(valueText).toLocaleString('en-US')
    },
    customizeTextSeries2({ valueText }) {
      if (['profit', 'revenue'].includes(this.series_2)) {
        return `${this.numberFormat(valueText)}`
      }

      return parseFloat(valueText).toLocaleString()
    },
    onLegendClick(e) {
      const series = e.target
      if (series.isVisible()) {
        series.hide()
      } else {
        series.show()
      }
    },
  },
}
</script>

<style scoped>

</style>
